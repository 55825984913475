<template>
  <v-card class="mx-auto" color="white" outlined>
    <v-row align="center">
      <v-col cols="2">
        <v-radio-group v-model="find" label="Buscar por:" @change="find_change()">
          <v-radio label="RUT" value="rut" />
          <v-radio label="Area" value="area" />
        </v-radio-group>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-if="find == 'rut'"
          v-mask="['X.XXX.XXX-X', 'XX.XXX.XXX-X']"
          v-model="rut"
          required
          prepend-inner-icon="mdi-card-account-details-outline"
          label="Ingrese rut"
          append-icon="mdi-magnify"
          maxlength="12"
          placeholder="12.345.678-9"
          @click:append="getData()"
          @keydown.enter="getData()"
        >
        </v-text-field>
        <v-select
          v-else
          v-model="area"
          dense
          :items="areas"
          append-icon="mdi-magnify"
          @click:append="getData()"
          @keydown.enter="getData()"
        />
      </v-col>
      <v-col cols="2">
        <v-btn color="green accent-4" class="white--text" @click="exportData()">
          Descargar todo
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="totalItems > 25" align="center" class="list mx-auto">
      <v-col cols="3">
        <v-select
          v-model="pageSize"
          :items="pageSizes"
          label="Articulos/Página"
          centered
          @change="handlePageSizeChange"
        />
      </v-col>
      <v-col cols="6">
        <v-pagination
          v-if="totalPages > 1"
          v-model="page"
          :length="totalPages"
          total-visible="10"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="handlePageChange"
        />
      </v-col>
    </v-row>
    <v-card class="list mx-auto" tile>
      <v-row>
        <v-card-title>Entregas de elementos de Seguridad</v-card-title>
        <v-spacer />
        <v-card-subtitle v-if="currentItems" v-text="'Mostrando ' + currentItems + ' de ' + totalItems" />
      </v-row>
      <v-data-table :items="userdata" :headers="headers" dense disable-pagination :hide-default-footer="true">
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-icon class="mr-2">
                mdi-information-variant
              </v-icon>
            </td>
            <td>{{ item.FECHA }}</td>
            <td>{{ item.NOMBRE }}</td>
            <td>{{ item.CECO }}</td>
            <td>{{ item.NCECO }}</td>
            <td>{{ item.T_MOV }}</td>
            <td>
              <v-checkbox v-model="item.BOTA" :value="item.BOTA" readonly />
            </td>
            <td>
              <v-checkbox v-model="item.FONO" :value="item.FONO" readonly />
            </td>
          </tr> </template
      ></v-data-table>
    </v-card>

    <v-dialog persistent v-model="nodata" width="50%">
      <v-card height="180">
        <v-card-title class="text-h5 cyan">
          Información
        </v-card-title>

        <v-card-text class="text-subtitle-1"
          ><v-icon x-large color="cyan">mdi-information-outline</v-icon>
          La búsqueda no contiene resultados.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-btn color="primary" text @click="nodata = false">
              Continuar
            </v-btn></v-row
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="download" width="50%">
      <v-card height="180">
        <v-card-title class="text-h5 cyan">
          Información
        </v-card-title>

        <v-card-text class="text-subtitle-1"
          ><v-icon x-large color="cyan">mdi-information-outline</v-icon>
          La búsqueda no contiene resultados.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-btn color="primary" text @click="nodata = false">
              Continuar
            </v-btn></v-row
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import botasService from "../services/botasService";
import XLSX from "xlsx";

export default {
  data: () => ({
    rut: undefined,
    area: undefined,
    userdata: [],
    find: "rut",
    headers: [
      { text: "", value: "actions", align: "center", sortable: false },
      { text: "Fecha", value: "FECHA", align: "start", sortable: false },
      { text: "Nombre", value: "NOMBRE", align: "start", sortable: false },
      { text: "CeCo", value: "CECO", align: "start", sortable: false },
      { text: "Nombre CeCo", value: "NCECO", align: "start", sortable: false },
      { text: "Tipo Entrega", value: "T_MOV", align: "start", sortable: false },
      { text: "Bota", value: "BOTA", align: "start", sortable: false },
      { text: "Fono", value: "FONO", align: "start", sortable: false },
    ],
    areas: [
      { text: "Administración", value: "ADMINISTRACION" },
      { text: "Ahumado", value: "AHUMADO" },
      { text: "Bodega", value: "BODEGA" },
      { text: "Casino", value: "CASINO" },
      { text: "Contratista", value: "CONTRATISTA" },
      { text: "Faena", value: "FAENA" },
      { text: "Frigorifico", value: "FRIGORIFICO" },
      { text: "Infraestructura", value: "INFRAESTRUCTURA" },
      { text: "Lavanderia", value: "LAVANDERIA" },
      { text: "Mantenimiento", value: "MANTENIMIENTO" },
      { text: "Otros", value: "OTROS" },
      { text: "Porteria", value: "PORTERIA" },
      { text: "Procesos Posteriores", value: "POSTERIORES" },
      { text: "Pulpas", value: "PULPAS" },
      { text: "SEMA", value: "SEMA" },
      { text: "Servicios Generales", value: "SS.GG." },
      { text: "Trozados", value: "TROZADOS" },
    ],
    nodata: false,
    download: false,
    page: 1,
    totalPages: 0,
    currentItems: undefined,
    totalItems: 0,
    pageSize: 25,
    pageSizes: [25, 50, 100],
  }),
  methods: {
    getData() {
      let data = {};
      if (this.rut) {
        data["rut"] = this.rut.replace(/\./g, "").toUpperCase();
      } else {
        data["area"] = this.area;
      }
      if (this.page) {
        data["page"] = this.page - 1;
      }
      if (this.pageSize) {
        data["size"] = this.pageSize;
      }
      botasService.getEntregas(data).then((response) => {
        if (response.data.entregas.length < 1) {
          this.nodata = true;
        } else {
          this.userdata = response.data.entregas.map(this.mapEntregas);
          this.totalPages = response.data.totalPages;
          this.currentItems = response.data.currentItems;
          this.totalItems = response.data.totalItems;
          this.nodata = false;
        }
      });
    },
    exportData() {
      botasService.exportar().then((response) => {
        console.log(response.data);
        let data = response.data.map((item) => {
          let tipo_ent = "";
          switch (item.t_mov) {
            case "new":
              tipo_ent = "NUEVO";
              break;
            case "replace":
              tipo_ent = "REPOSICION";
              break;
            case "renew":
              tipo_ent = "RENOVACION";
            // eslint-disable-next-line no-fallthrough
            default:
              break;
          }
          return {
            Rut: item.rut,
            Nombre: item.nom_comp,
            Centro_de_costo: item.ceco,
            Descripcion_ceco: item.nom_ceco,
            Area: item.area,
            Tipo_entrega: tipo_ent,
            Entrega_bota: item.bota ? "SI" : "NO",
            Talla_bota: item.talla_b,
            Entrega_fono: item.fono ? "SI" : "NO",
            Registrado_por: item.usr_reg,
            Fecha_registro: item.fecha_reg ? new Date(item.fecha_reg) : "",
            Entregado_por: item.usr_entr,
            Fecha_entrega: item.fecha_ent ? new Date(item.fecha_entr) : "",
            Entregado: item.entregado ? "SI" : "NO",
            Observación: item.comment,
          };
        });
        let codig = XLSX.utils.json_to_sheet(data, { cellDates: true, dateNF: "dd-mm-yyyy" });
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, codig, "ENTREGAS");
        XLSX.writeFile(wb, "ENTREGAS.xlsx", { compression: true });
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getData();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getData();
    },
    mapEntregas(data) {
      let tmov_;
      if (data.t_mov == "new") {
        tmov_ = "Nueva";
      } else if (data.t_mov == "replace") {
        tmov_ = "Reposición";
      } else {
        tmov_ = "Renovación";
      }
      let fecha = data.fecha_reg.toString().split("T")[0];
      return {
        id: data.id,
        FECHA: fecha,
        NOMBRE: data.nom_corto,
        CECO: data.ceco,
        NCECO: data.nom_ceco,
        T_MOV: tmov_,
        BOTA: data.bota,
        FONO: data.fono,
      };
    },
    find_change() {
      this.rut = undefined;
      this.area = undefined;
      this.userdata = [];
    },
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) {
      this.$router.push("/login");
    }
  },
};
</script>
